
import { defineComponent, toRaw } from 'vue';
import { WordCloud } from '@antv/g2plot';
export default defineComponent({
  name: 'WordCloud',
  props: ['title', 'chartData'],
  mounted() {
    // this.setChart(toRaw(this.chartData));
  },
  updated() {
    this.setChart(toRaw(this.chartData));
  },
  methods: {
    setChart(data: any) {
      data = Object.values(data).slice(0, 30);

      const wordCloud = new WordCloud('word-cloud-container', {
        data,
        wordField: 'type',
        weightField: 'value',
        colorField: 'type',
        wordStyle: {
          fontFamily: 'Verdana',
          fontSize: [8, 32],
          rotation: 0,
        },
        // 返回值设置成一个 [0, 1) 区间内的值，
        // 可以让每次渲染的位置相同（前提是每次的宽高一致）。
        random: () => 0.5,
      });

      wordCloud.render();
    },
  },
});
