
import { defineComponent } from 'vue';
import { Gauge } from '@antv/g2plot';

export default defineComponent({
  name: 'DiagnosisSpeed',
  props: ['title', 'chartIndex', 'reloadTime'],
  data() {
    return {};
  },
  components: {},
  mounted() {
    this.setChart(this.random());
  },
  methods: {
    setChart(data: number) {
      const gauge = new Gauge('DiagnosisSpeed', {
        percent: data,
        range: {
          color: '#18c7d8',
        },
        indicator: {
          pointer: {
            style: {
              stroke: '#19d1e1',
            },
          },
          pin: {
            style: {
              stroke: '#D0D0D0',
            },
          },
        },
        axis: {
          label: {
            formatter(v) {
              return Number(v) * 1000;
            },
          },
          subTickLine: {
            count: 3,
          },
        },
        statistic: {
          content: {
            formatter: () => '平均诊断时长(秒)',
            offsetY: 42,
            style: {
              color: '#19d1e1',
              fontSize: '30px',
            },
          },
        },
      });

      gauge.render();
      setInterval(() => {
        gauge.changeData(this.random());
      }, this.reloadTime * 60 * 1000);
    },
    random() {
      // 生成10-30之间的随机数
      return (Math.floor(Math.random() * (50 - 10 + 1)) + 10) * 0.01;
    },
  },
});
