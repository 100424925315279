import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "chart-t" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dv_scroll_board = _resolveComponent("dv-scroll-board")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createVNode(_component_dv_scroll_board, {
      id: "doctor-top",
      config: _ctx.config
    }, null, 8, ["config"])
  ], 64))
}