
import { defineComponent } from 'vue';
import { Scene, LineLayer, PointLayer } from '@antv/l7';
import { GaodeMap } from '@antv/l7-maps';
import world from '../assets/json/world.json';
import china from '../assets/json/china.json';
import hospital_geo from '../assets/json/geo.json';
import DataServer from '@/server/DataServer';

export default defineComponent({
  name: 'MapView',
  props: ['chartIndex'],
  data() {
    return {
      hospitalRank: [],
      showloading: true,
    };
  },
  mounted() {
    this.getHospitalRank(this.chartIndex);
  },
  methods: {
    setMap(data: any) {
      const scene = new Scene({
        id: 'map-chart',
        map: new GaodeMap({
          pitch: 35.210526315789465,
          mapStyle: 'dark',
          center: [104.288144, 31.239692],
          zoom: 3.6,
          token: 'eeff19f80e1dca5375971c9fe031132a',
        }),
      });
      scene.on('loaded', () => {
        const worldLine = new LineLayer().source(world).color('#1E90FF').size(0.5).style({
          opacity: 0.1,
        });
        scene.addLayer(worldLine);

        const chinaLine = new LineLayer().source(china).color('#18c7d8').size(0.5).style({
          opacity: 0.4,
        });
        scene.addLayer(chinaLine);

        const pointLayer = new PointLayer({})
          .source(data.list, {
            parser: {
              type: 'json',
              x: 'j',
              y: 'w',
            },
          })
          .shape('cylinder')
          .size('floor', [0, 10000])
          .size('t', function (level) {
            return [3, 3, level];
          })
          .animate(true)
          .active(true)
          .color('t', ['#e4f5f8', '#c6eaf0', '#a2dfe8', '#74d3e0', '#18c7d8', '#15b2c1', '#139aa7', '#0f7e89', '#18c7d8'])
          .style({
            opacity: 1.0,
          });
        scene.addLayer(pointLayer);
      });
    },
    getHospitalRank(index: number) {
      DataServer.getChartData(index).then((res: any) => {
        if (res.status === 200) {
          this.hospitalRank = res.data.result[0].data;
          this.getHospitalGeo(this.hospitalRank);
        }
      });
    },
    getHospitalGeo(data: Array<any>) {
      let d: any[] = [];
      data.forEach((item: any) => {
        const geo = hospital_geo.find((geo: any) => geo.name === item.name);
        if (geo) {
          let geoArray = geo.location.split(',');
          item.j = geoArray[0];
          item.w = geoArray[1];
          item.level = 1;
          //{"w":22.7833,"t":17.7000,"s":"广西","l":12,"m":"南宁","j":108.5500,"h":"59431"}
          d.push({
            j: geoArray[0],
            w: geoArray[1],
            t: item.value,
            l: 100,
            m: item.name,
            h: 1000,
          });
        }
      });
      this.showloading = false;
      this.setMap({
        unit: '',
        eleValue: 'm,h,w,j,s,l,t',
        dateTime: '20181113010000',
        list: d,
      });
    },
  },
});
