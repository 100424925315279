
import { defineComponent, toRaw } from 'vue';
import { Pie } from '@antv/g2plot';
export default defineComponent({
  name: 'DiseaseType',
  props: ['title', 'chartData'],
  mounted() {
    // this.setChart(toRaw(this.chartData));
  },
  updated() {
    this.setChart(toRaw(this.chartData));
  },
  methods: {
    setChart(data: any) {
      data = Object.values(data).slice(0, 10);

      const piePlot = new Pie('disease-type', {
        color: ['#0b5961', '#0f7e89', '#139aa7', '#15b2c1', '#18c7d8', '#74d3e0', '#a2dfe8', '#c6eaf0', '#e4f5f8'],
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'type',
        radius: 0.8,
        label: {
          type: 'outer',
          content: '{name}\n{percentage}',
          style: {
            fill: '#fff',
            fontSize: 12,
          },
        },
        legend: {
          itemName: {
            style: {
              fill: '#18c7d8',
              fontSize: 12,
            },
          },
        },
        interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }],
      });

      piePlot.render();
    },
  },
});
