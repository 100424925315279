import axios from 'axios';
import Lodash from 'lodash';
import ichd_original from '../assets/json/ichd.json';

const ichd = Lodash.flatMap(ichd_original.classification, function (n) {
  if (n.children.length > 0) {
    const _n = { ...n };
    delete _n.children;
    const children: any[] = n.children;
    return [_n].concat(children);
  } else {
    return n.children;
  }
});

const API_HOST = process.env.VUE_APP_BASE_API;
const PORTAL_HOST = process.env.VUE_APP_PORTAL_API;

function getChartData(ChartIndex: number) {
  return axios.get(`${API_HOST}/chart/${ChartIndex}/data/`);
}

function getChartDataTody(ChartIndex: number, Day: string) {
  const body: any = {
    datasource: {
      id: ChartIndex,
      type: 'table',
    },
    force: false,
    queries: [
      {
        time_range: 'No filter',
        granularity: 'created_at',
        filters: [
          {
            col: 'created_at',
            op: '>=',
            val: Day + ' 00:00:00',
          },
        ],
        extras: {
          time_grain_sqla: 'P1D',
          having: '',
          having_druid: [],
          where: '',
        },
        applied_time_extras: {},
        columns: [],
        metrics: ['count'],
        annotation_layers: [],
        timeseries_limit: 0,
        order_desc: true,
        url_params: {},
        custom_params: {},
        custom_form_data: {},
      },
    ],
    result_format: 'json',
    result_type: 'full',
  };
  return axios.post(`${API_HOST}/chart/data`, body);
}

function formartHeadacheTypesData(data: any) {
  let dataArray: any = [];
  data.forEach((item: any) => {
    let t = formatFromIchd(ichd, JSON.parse(item.classification));
    t.forEach((element: any) => {
      let dItem = Lodash.find(dataArray, function (o) {
        return o.type === element.title;
      });
      if (dItem) {
        dItem.value += item.num;
      } else {
        dataArray.push({
          type: element.title,
          value: item.num,
        });
      }
    });
  });
  //返回降序排列
  return Lodash.sortBy(dataArray, 'value').reverse();
}

function formatFromIchd(array: any, keys: any): any {
  let result: any = [];
  keys.forEach((key: string) => {
    let l = Lodash.find(array, function (o) {
      return o.value === key;
    });
    if (l) {
      result.push({
        title: l.title['zh-cn'],
        // value: l.value,
      });
    }
  });
  return result;
}

function login(username: string, password: string) {
  return axios.post(`${PORTAL_HOST}/auth/login`, {
    username,
    password,
  });
}

export default {
  getChartData,
  getChartDataTody,
  formartHeadacheTypesData,
  login,
};
