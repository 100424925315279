import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { id: "main" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { class: "left" }
const _hoisted_6 = { class: "realTime" }
const _hoisted_7 = { class: "monthly" }
const _hoisted_8 = { class: "hospitalTop" }
const _hoisted_9 = { class: "center" }
const _hoisted_10 = { class: "map-box" }
const _hoisted_11 = { class: "total-info" }
const _hoisted_12 = { class: "fly-line" }
const _hoisted_13 = { class: "dicases" }
const _hoisted_14 = { class: "wordcloud-box" }
const _hoisted_15 = { class: "disease-type-box" }
const _hoisted_16 = { class: "right" }
const _hoisted_17 = { class: "DoctorTop" }
const _hoisted_18 = { class: "DiagnosisSpeed" }
const _hoisted_19 = { class: "Brain" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleView = _resolveComponent("TitleView")!
  const _component_RealTime = _resolveComponent("RealTime")!
  const _component_MonthData = _resolveComponent("MonthData")!
  const _component_HospitalTop = _resolveComponent("HospitalTop")!
  const _component_TotalInfo = _resolveComponent("TotalInfo")!
  const _component_MapView = _resolveComponent("MapView")!
  const _component_WordCloud = _resolveComponent("WordCloud")!
  const _component_DiseaseType = _resolveComponent("DiseaseType")!
  const _component_DoctorTop = _resolveComponent("DoctorTop")!
  const _component_DiagnosisSpeed = _resolveComponent("DiagnosisSpeed")!
  const _component_BrainView = _resolveComponent("BrainView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "fullscreen",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.fullscreen()))
    }, [
      (_ctx.isfullscreen)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "退出全屏"))
        : (!_ctx.isfullscreen)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "点击全屏"))
          : _createCommentVNode("", true)
    ]),
    _createVNode(_component_TitleView, {
      class: "title",
      title: _ctx.title
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_RealTime, {
            title: '实时诊断数据',
            chartIndex: _ctx.chartIndex.RealTime,
            reloadTime: 5
          }, null, 8, ["chartIndex"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_MonthData, {
            title: '诊断数据月统计',
            chartIndex: _ctx.chartIndex.Monthly,
            reloadTime: 60
          }, null, 8, ["chartIndex"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_HospitalTop, {
            title: '医院排名',
            chartIndex: _ctx.chartIndex.HospitalTop,
            reloadTime: 30
          }, null, 8, ["chartIndex"])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_TotalInfo, {
              chartIndex: _ctx.chartIndex.TotalInfo,
              startDay: '2022-05-01',
              reloadTime: 5
            }, null, 8, ["chartIndex"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_MapView, {
              chartIndex: _ctx.chartIndex.HospitalTop
            }, null, 8, ["chartIndex"])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_WordCloud, {
              chartData: _ctx.headacheTypesData,
              reloadTime: 720
            }, null, 8, ["chartData"])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_DiseaseType, {
              chartData: _ctx.headacheTypesData,
              reloadTime: 720
            }, null, 8, ["chartData"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _createVNode(_component_DoctorTop, {
            title: '医生诊断数量排名',
            chartIndex: _ctx.chartIndex.DoctorTop,
            reloadTime: 30
          }, null, 8, ["chartIndex"])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createVNode(_component_DiagnosisSpeed, {
            title: '诊断速度',
            reloadTime: 3
          })
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createVNode(_component_BrainView)
        ])
      ])
    ])
  ]))
}