
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BrainView',
  props: {
    title: String,
  },
  data() {
    return {
      // title: 'CDSS辅助诊断系统数据大屏',
    };
  },
  components: {},
});
