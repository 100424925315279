
import { defineComponent } from 'vue';
import DataServer from '@/server/DataServer';

export default defineComponent({
  name: 'LoginView',
  props: ['title', 'chartIndex', 'reloadTime'],
  data() {
    return {
      userName: '',
      password: '',
      remember: false,
    };
  },
  components: {},
  mounted() {
    this.loadRemeber();
  },
  methods: {
    login() {
      DataServer.login(this.userName, this.password)
        .then((res: any) => {
          if (res.status === 200) {
            window.sessionStorage.setItem('user', JSON.stringify(res.data));
            this.$router.push('/home');
            if (this.remember) {
              this.rememberMe();
            } else {
              this.removeRemember();
            }
          }
        })
        .catch((err: any) => {
          alert('登录失败，请检查用户名或者密码');
          window.console.log(err);
        });
    },
    rememberMe() {
      window.localStorage.setItem(
        'loginUser',
        JSON.stringify({
          userName: this.userName,
          password: this.password,
          remember: this.remember,
        })
      );
    },
    removeRemember() {
      window.localStorage.removeItem('loginUser');
    },
    loadRemeber() {
      const loginUser = window.localStorage.getItem('loginUser');
      if (loginUser) {
        const user = JSON.parse(loginUser);
        this.userName = user.userName;
        this.password = user.password;
        this.remember = user.remember;
      }
    },
  },
});
