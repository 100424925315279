
import { defineComponent, reactive } from 'vue';
import DataServer from '@/server/DataServer';

export default defineComponent({
  name: 'HospitalTop',
  props: {
    title: String,
    chartIndex: Number,
    reloadTime: Number,
  },
  data() {
    return {
      config: reactive({
        rowNum: 7,
        data: [],
        unit: '例',
      }),
    };
  },
  components: {},
  mounted: function () {
    this.getHospitalRank(Number(this.chartIndex));
    setInterval(async () => {
      this.getHospitalRank(Number(this.chartIndex));
    }, this.reloadTime * 60 * 1000);
  },
  methods: {
    getHospitalRank(index: number) {
      DataServer.getChartData(index).then((res: any) => {
        if (res.status === 200) {
          this.config.data = res.data.result[0].data;
        }
      });
    },
  },
});
