
import { defineComponent } from 'vue';
import TitleView from '../components/Title.vue';
import TotalInfo from '../components/TotalInfo.vue';
import MapView from '../components/Map.vue';
import WordCloud from '../components/WordCloud.vue';
import DiseaseType from '../components/DiseaseType.vue';
import RealTime from '../components/RealTime.vue';
import HospitalTop from '../components/HospitalTop.vue';
import MonthData from '../components/MonthData.vue';
import DoctorTop from '../components/DoctorTop.vue';
import DiagnosisSpeed from '../components/DiagnosisSpeed.vue';
import BrainView from '../components/Brain.vue';
import DataServer from '@/server/DataServer';

export default defineComponent({
  name: 'HomeView',
  data() {
    return {
      title: 'CDSS辅助诊断系统数据大屏',
      headacheTypesData: [],
      chartIndex: {
        RealTime: 9,
        Monthly: 2,
        HospitalTop: 8,
        DoctorTop: 4,
        DiseaseType: 6,
        TotalInfo: [7, 3, 5],
      },
      isfullscreen: false,
    };
  },
  components: {
    TitleView,
    TotalInfo,
    MapView,
    WordCloud,
    DiseaseType,
    RealTime,
    HospitalTop,
    MonthData,
    DoctorTop,
    DiagnosisSpeed,
    BrainView,
  },
  beforeMount() {
    this.checkLogin();
  },
  mounted() {
    this.getHeadacheTypesData(this.chartIndex.DiseaseType);
    //监听是否为全屏状态
    if (window.screen.height - window.document.body.offsetHeight > 5) {
      this.isfullscreen = false;
    } else {
      this.isfullscreen = true;
    }
    window.addEventListener('resize', this.fullScreenChangeFn);
  },
  methods: {
    getHeadacheTypesData(index: number) {
      DataServer.getChartData(index).then((res: any) => {
        if (res.status === 200) {
          this.headacheTypesData = { ...DataServer.formartHeadacheTypesData(res.data.result[0].data) };
        }
      });
    },
    checkLogin() {
      const loginUser = window.sessionStorage.getItem('user');
      if (!loginUser) {
        this.$router.push('/login');
      }
    },
    fullscreen() {
      //切换全屏
      if (document.fullscreenElement) {
        document.exitFullscreen();
        document.getElementById('app').style.transform = 'scale(1)';
        this.isfullscreen = false;
      } else {
        document.body.requestFullscreen();
        //给app添加缩放样式
        document.getElementById('app').classList.add('fullscreen-scal');
        //计算全屏缩放比例
        const scaleW = screen.width / 1920;
        const scaleH = screen.height / 1080;
        //将app缩放为全屏
        document.getElementById('app').style.transform = 'scale(' + scaleW + ',' + scaleH + ')';
        this.isfullscreen = true;
      }
    },
    //监听屏幕是否全屏
    fullScreenChangeFn() {
      if (window.screen.height - window.document.body.offsetHeight > 5) {
        this.isfullscreen = false;
        document.getElementById('app').style.transform = 'scale(1)';
      } else {
        this.isfullscreen = true;
      }
    },
  },
});
