
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TitleView',
  props: {
    title: String,
  },
  data() {
    return {
      // title: 'CDSS辅助诊断系统数据大屏',
      time: '',
    };
  },
  components: {},
  mounted() {
    setInterval(() => {
      this.time = new Date().toLocaleString();
    }, 1000);
  },
});
