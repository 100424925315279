
import { defineComponent } from 'vue';
import { Column } from '@antv/g2plot';
import DataServer from '@/server/DataServer';

export default defineComponent({
  name: 'MonthData',
  props: ['title', 'chartIndex', 'reloadTime'],
  data() {
    return {
      // title: 'CDSS辅助诊断系统数据大屏',
    };
  },
  components: {},
  mounted: function () {
    this.setChart();
  },
  methods: {
    async getData(index: number) {
      return new Promise<void>((resolve, reject) => {
        DataServer.getChartData(index)
          .then((res: any) => {
            if (res.status === 200) {
              resolve(res.data.result[0].data);
            }
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },
    async setChart() {
      let data: any = await this.getData(this.chartIndex);
      data = this.formatData(data);
      const columnPlot = new Column('monthData', {
        data,
        xField: 'month',
        yField: 'value',
        color: '#19d1e1',
        label: {
          // 可手动配置 label 数据标签位置
          position: 'top', // 'top', 'bottom', 'middle',
          // 配置样式
          style: {
            fill: '#fff',
            opacity: 0.6,
          },
          offsetY: 3,
        },
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
          },
        },
        yAxis: {
          grid: {
            line: {
              style: {
                lineWidth: 0,
              },
            },
          },
        },
        meta: {
          month: {
            alias: '月份',
          },
          value: {
            alias: '病例数',
          },
        },
      });

      columnPlot.render();
      setInterval(async () => {
        let data: any = await this.getData(this.chartIndex);
        data = this.formatData(data);
        columnPlot.changeData(data);
      }, this.reloadTime * 60 * 1000);
    },
    formatData(data: any) {
      const array: { month: string; value: number }[] = [];
      for (let i = 1; i <= 12; i++) {
        array.push({
          month: String(i),
          value: 0,
        });
      }
      for (let i = 0; i < data.length; i++) {
        let nArray = data[i].month.split('-');
        array[Number(nArray[1] - 1)].value = data[i].value;
      }
      return array;
    },
  },
});
